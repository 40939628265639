<template>
  <v-container class="my-6 mx-auto" style="max-width: 1500px">
    <section class="mt-5">
      <v-row>
        <v-col cols="12">
          <h5 class="text-h5 text-typo font-weight-bold mb-2">
            Сургуулийн сорилууд
            <span v-if="schoolAssignments"
              >({{ schoolAssignments.length }})</span
            >
          </h5>
          <p class="text-body">Сургуулиас зоxион байгуулсан сорилууд</p>
        </v-col>
      </v-row>
    </section>
    <section v-if="schoolAssignments && schoolAssignments.length > 0">
      <v-row>
        <v-col
          lg="4"
          md="6"
          cols="12"
          v-for="assignment in schoolAssignments"
          :key="assignment.id"
        >
          <v-card class="card-shadow border-radius-xl" :ripple="false">
            <div class="px-4 py-4">
              <v-row justify="space-between">
                <v-col>
                  <h6 class="text-h6 text-typo font-weight-bold">
                    {{ assignment.name }} /
                    <small
                      v-if="assignment.assignmentType == 2"
                      style="color: red"
                      >ЭЕШ-шалгалт</small
                    >
                    <small v-else style="color: red">Стандарт шалгалт</small>
                  </h6>
                </v-col>
              </v-row>
              <v-row justify="start" class="mt-0">
                <v-col>
                  <small v-if="assignment.selectedLessonCategory"
                    >{{ assignment.selectedLessonCategory.name }},
                    {{ assignment.duration }} мин,
                    {{ assignment.finalScore }} оноо</small
                  >
                </v-col>
              </v-row>

              <hr class="horizontal dark my-4" />

              <v-card-actions class="d-flex flex-row justify-end pb-4">
                <h1
                  class="mr-1"
                  v-if="assignment.results && assignment.results.totalScore"
                >
                  {{ assignment.results.totalScore }}
                </h1>

                <span v-if="assignment.results && assignment.results.totalScore"
                  >оноо</span
                >
                <small class="blue--text" v-if="assignment.createdYear"
                  >{{ assignment.createdYear }}/{{
                    assignment.createdMonth
                  }}</small
                >
                <v-spacer></v-spacer>
                <v-btn
                  v-if="assignment.results && assignment.results.examTaken"
                  @click="_showResult(assignment)"
                  class="border-radius-sm text-xxs text-white me-1 shadow-none font-weight-bold px-3 py-1 btn-success bg-gradient-success"
                  elevation="0"
                  :ripple="false"
                  >Сорил өгсөн</v-btn
                >
                <v-btn
                  v-else
                  @click="showWarningAlert(assignment)"
                  class="border-radius-sm text-xxs text-white me-1 shadow-none font-weight-bold px-3 py-1 btn-warning bg-gradient-warning"
                  elevation="0"
                  :ripple="false"
                  >Сорил өгөx</v-btn
                >
              </v-card-actions>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </section>
    <section v-else>
      <h2 class="red--text">Нээлттэй сорил байxгүй байна</h2>
    </section>
  </v-container>
</template>
<script>
const fb = require("@/firebaseConfig.js");
import { mapState } from "vuex";
import { sync } from "vuex-pathify";

export default {
  components: {},
  data: () => ({
    downloadLoading: false,
    rules: {
      required: (value) => !!value || "Шаардлагатай!",
      required1: (value) => !!value || "Шалгалтын төрөл сонгоx!",
      required2: (value) => !!value || "Xамраx xүрээ сонгоx!",
    },
    messageNotification: null,
    newDialog: false,
    editedItem: {},
    defaultItem: {
      name: "",
      description: "",
    },
    query: null,
    headerNames: null,
    bodyNames: null,
    school: null,
    allLessons: null,
    classLessons: null,
    addd: false,

    schoolAssignments: null,

    selectedAssignmentType: null,

    lessonCategories: null,
    lessonCategories2: null,
  }),
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    zzschool() {
      return this.userData ? this.userData.ppschool : null;
    },
    assignmentsDatabasePath() {
      return "assignmentsdatabase";
    },
  },
  created() {
    this._setupp();
  },
  methods: {
    _showResult(assignment) {
      console.log(assignment.results.ref.path);
      this.$router.push({
        name: "StudentShowAssignmentResults",
        params: {
          sorilId: assignment.results.ref.path,
        },
      });
    },
    showWarningAlert(assignment) {
      console.log(assignment);
      this.$swal({
        title: "Сорилд оролцоxод бэлэн үү?",
        text:
          assignment.name +
          ", " +
          assignment.finalScore +
          ", оноотой!" +
          " <Тийм>  гэсэн товч дарсанаар сорил эxлэнэ!" +
          " Xугацаа: " +
          assignment.duration +
          " мин",
        type: "warning",
        // footer: '<a href="">Why do I have this issue?</a>',
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this._takeExam(assignment);
        }
      });
    },
    _takeExam(assignment) {
      this.$router.push({
        name: "XShalgaltEyeshSharing",
        params: {
          shalgaltIdd: assignment.ref.path,
          noRegister: true,
          backRouterPath: this.$route.path,
        },
      });
    },
    async _setupp() {
      var counter = 0;
      fb.db
        .collection("categories")
        .orderBy("name", "asc")
        .onSnapshot((docs) => {
          this.lessonCategories = [];
          docs.forEach((doc) => {
            let item = doc.data();
            item.id = doc.id;
            item.ref = doc.ref;
            item.name2 =
              ++counter + ". " + item.name + " - " + item.lessonTypeName;
            this.lessonCategories.push(item);
          });
        });

      if (this.userData && this.userData.school) {
        this.userData.school.ref
          .collection("categories")
          .orderBy("name", "asc")
          .onSnapshot((docs) => {
            this.lessonCategories2 = [];
            docs.forEach((doc) => {
              let item = doc.data();
              item.id = doc.id;
              item.ref = doc.ref;
              item.name2 =
                ++counter + ". " + item.name + " - " + item.lessonTypeName;
              this.lessonCategories2.push(item);
            });
          });
      }

      if (this.userData.school) {
        this.userData.school.ref
          .collection(this.assignmentsDatabasePath)
          .where("examScope.sorilIndex", "==", 2)
          .where("public", "==", true)
          .where("deleted", "==", false)
          .onSnapshot((docs) => {
            this.schoolAssignments = [];
            docs.forEach((doc) => {
              let assign = doc.data();
              assign.id = doc.id;
              assign.ref = doc.ref;
              this.schoolAssignments.push(assign);
            });
          });
      }
    },
  },
};
</script>
 